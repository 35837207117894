/*
 * This is a very simple reducer, it reacts to all the events triggered from
 * middleware/auth.js
 */
export default (state = {}, action) => {
  switch (action.type) {
    case 'AUTH_SET':
    case 'AUTH_UPDATE':
      const {type, ...authData} = action;
      return {
        ...state,
        ...authData,
      };

    case 'SET_UNAUTHORISED':
      return {
        ...state,
        unauthorised: true,
      };

    case 'API_REQUEST_COMPLETE':
      if (action.status !== 'error' && action.id === 'COMPANY') {
        return {
          ...state,
          company: action.data.results,
        };
      } else return state;

    case 'AUTH_CLEAR':
      return {};

    default:
      return state;
  }
};
