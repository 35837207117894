import {connect} from 'react-redux';

import Component from '../Components/FilterMenu';

const mapStateToProps = state => {
  return {
    isMobile: state.ui.isMobile,
    showFilterMenu: state.ui.showFilterMenu || !state.geoAreas.selectedSite,
    isUnauthorised: state.auth.unauthorised,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleFilterMenu() {
      dispatch({type: 'UI_TOGGLE_FILTER_MENU'});
    },
    openCreateAreaModal() {
      dispatch({type: 'SHOW_MODAL', title: 'createGeoArea'});
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
