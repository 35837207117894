export default (state = {}, action) => {
  const isPhysicalResponse =
    action.id === 'PHYSICAL_LOCATIONS' || action.id === 'PHYSICAL_SITES';
  const isValidApiResponse =
    action.type === 'API_REQUEST_COMPLETE' && action.status !== 'error';
  if (!isPhysicalResponse || !isValidApiResponse) return state;

  if (action.id === 'PHYSICAL_LOCATIONS') {

    const r = action.data.results;
    const children = r[Object.keys(r)[0]].children || [];
    const physicalLocations = children.map(flatten);

    return {
      ...state,
      physicalLocations,
      byId: {
        ...state.byId,
        ...physicalLocations.reduce(mapById, {}),
      },
    };
  } else if (action.id === 'PHYSICAL_SITES') {
    const physicalSites = action.data.results
      .filter(l => l.status !== '0')
      .map(l => ({...l, id: l.term_id}));

    return {
      ...state,
      physicalSites,
      byId: {
        ...state.byId,
        ...physicalSites.reduce(mapById, {}),
      },
    };
  }
};

function mapById(obj, physicalLocation) {
  const children = physicalLocation.children || [];

  return {
    ...obj,
    [physicalLocation.term_id]: physicalLocation,
    ...children.reduce(mapById, {}),
  };
}

function flatten(obj) {
  const item = obj[Object.keys(obj)[0]];

  return {
    ...item,
    id: item.term_id,
    children: item.children ? item.children.map(flatten) : [],
  };
}
