/*
 * Sets whether an endpoint is in a loading state, using the "id" of an API
 * request. This makes it easy to add a spinner if a certain endpoint is
 * currently loading
 */
export default (state = {}, action) => {
  switch (action.type) {
    case 'API_REQUEST_STARTED':
      return {
        ...state,
        [action.id]: true,
      };

    case 'API_REQUEST_COMPLETE':
      return {
        ...state,
        [action.id]: false,
      };
    default:
      return state;
  }
};
