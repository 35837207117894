/**
 * Initializes Intercom with the provided domain and optional payload data.
 * @param {string} domain - The domain associated with the Intercom configuration.
 * @param {string} currentCookieLanguage - Current language selected by the user stored in cookie.
 * @param {Object} payload - (Optional) Additional data to include in the Intercom configuration.
 * @param {string} envName - Current environment stage (e.g., 'prod', 'dev').
 */
export function intercomMessenger(domain, currentCookieLanguage, payload = {}, envName) {
  const appId = envName === 'prod' ? 'tlmqy4u1' : 'slathgp8';

  // Construct the Intercom configuration object
  const intercomConfig = {
    app_id: appId,
    api_base: 'https://api-iam.intercom.io',
    domain,
    ...payload,
  };

  // Check if the Intercom script is loaded
  if (typeof window.Intercom !== 'function') {
    console.error('Intercom is not loaded. Make sure the Intercom script has been included.');
    return;
  }

  try {
    // Boot Intercom with the constructed configuration
    window.Intercom('boot', intercomConfig);

    // Update language override after booting Intercom
    window.intercomSettings = {
      language_override: currentCookieLanguage,
      ...intercomConfig,
    };

    // Call Intercom's update method to apply the language change
    window.Intercom('update', window.intercomSettings);
  } catch (error) {
    console.error('Error while initializing or updating Intercom:', error);
  }
}
