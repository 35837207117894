import React from 'react';
import {connect} from 'react-redux';

/*
 * While normally in this project we have separate Containers/Components
 * code, I've put them both in here to make development easier. This is because
 * this component is called from almost every other component, so './Text' is
 * nicer than '../Containers/Text' when used so often.
 *
 * Quite simply looks up its child content as a key against the language
 * that is currently set in the store. Defaults to just showing the child
 * content.
 */
const mapStateToProps = (state, ownProps) => {
  return {t: state.language.t, k: ownProps.children};
};

const Component = ({t, k}) => {
  return <React.Fragment>{t[k] || k}</React.Fragment>;
};

export default connect(mapStateToProps)(Component);
