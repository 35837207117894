// Re-usable utility functions

/*
 * Look up if a given value is in a passed array. If it is, it remove it, and return the array.
 * If passed data is not an array, return it unmodified
 * If array null or undefined, return it unmodified
 * If value is not in the passed array, return it unmodified
 */
export function removeFromArray(arr, value) {

  if (!(arr instanceof Array)) {
    return arr;
  }

  if (arr && arr !== 'undefined') {
    const index = arr.indexOf(value);

    if (index !== -1) {
      return [...arr.slice(0, index), ...arr.slice(index + 1)];
    }
  }

  return arr;

}
