export default (state = {}, action) => {
  switch (action.type) {
    case 'API_REQUEST_COMPLETE':
      if (action.id === 'GET_OVERLAYS' && action.status === 'success') {
        /*
         * Sorted in ascending order.
         * This code ensures that only most recent uploads are rendered
         */
        const overlayMap = action.data
          .sort((a, b) => {
            return (
              new Date(a.lastModified).getTime() -
              new Date(b.lastModified).getTime()
            );
          })
          .reduce((obj, overlay) => {
            overlay.geoAreas.forEach(geoArea => {
              obj[geoArea.physicalLocationId] = {
                ...overlay,
                geoArea,
              };
            });

            return obj;
          }, {});

        /* Now flatten back to array */
        const overlays = Object.keys(overlayMap).map(k => overlayMap[k]);

        return {
          ...state,
          overlaysWithCoords: overlays.map(o => ({
            id: o.id,
            geoAreaId: o.geoArea.physicalLocationId,
            depth: o.geoArea.depth,
            coords: toCoords(o.geoArea.geoJson),
            minZoom: o.minZoom,
            maxZoom: o.maxZoom,
          })),
        };
      }

      return state;

    /*
     * An update for when the user updates the depth of the geoarea
     */
    case 'UPDATE_GEO_AREA_DEPTH':
      const [prev] = state.overlaysWithCoords.filter(
        o => o.geoAreaId === action.physicalLocationId,
      );

      return {
        ...state,
        overlaysWithCoords: state.overlaysWithCoords.map(o => {
          const update =
            o.geoAreaId === action.physicalLocationId
              ? {
                  depth: action.depth,
                }
              : {};

          return {
            ...o,
            ...update,
          };
        }),
        visibleDepths: state.visibleDepths.map(d => {
          if (prev && prev.depth && prev.depth === d) {
            return action.depth;
          }

          return d;
        }),
      };

    case 'SET_MAP_BOUNDS':
      return {
        ...state,
        mapBounds: action.bounds,
        zoom: action.zoom,
      };

    case 'TOGGLE_DEPTH_VISIBILITY':
      return {
        ...state,
        visibleDepths:
          state.visibleDepths.indexOf(action.depth) > -1
            ? state.visibleDepths.filter(d => d !== action.depth)
            : [...state.visibleDepths, action.depth],
      };

    default:
      return state;
  }
};

export function toCoords(geoJson) {
  return JSON.parse(geoJson).geometry.coordinates[0].map(c => [c[1], c[0]]);
}
