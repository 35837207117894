import {connect} from 'react-redux';

import Component from '../Components/SiteSelector';

const mapStateToProps = state => {
  return {
    sites: state.geoAreas.sites,
    selectedSite: state.geoAreas.selectedSite,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSelect(site) {
      dispatch({type: 'SET_SELECTED_SITE', site});
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
