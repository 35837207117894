import {connect} from 'react-redux';

import Component from '../Components/HierarchyTree';

const mapStateToProps = state => {
  return {
    visibleIds: state.geoAreas.visibleIds,
    publishedIds: state.geoAreas.publishedIds,
    openedIds: state.geoAreas.openedIds,
    hierarchy: state.geoAreas.selectedSite
      ? state.geoAreas.hierarchyBySiteId[state.geoAreas.selectedSite]
      : null,
    height: state.ui.height,
    isMobile: state.ui.isMobile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleOpened(id) {
      dispatch({type: 'TOGGLE_OPENED', id});
    },
    setVisibility(ids, isChecked) {
      dispatch({type: 'SET_VISIBILITY', ids, isChecked});
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
