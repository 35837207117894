/*
 * This abstraction keeps the file upload logic out of our React components.
 */
export default store => next => action => {
  next(action);

  if (
    action.type === 'API_REQUEST_COMPLETE' &&
    action.id === 'CREATE_OVERLAY' &&
    action.status === 'success'
  ) {
    const file = store.getState().mapTiler.file;
    const uploadUrl = action.data.signedUrl;

    upload(file, uploadUrl, store.dispatch);
  }
};

/*
 * Lifted bulk of the code from here: https://github.com/odysseyscience/react-s3-uploader/blob/master/s3upload.js
 */
function upload(file, url, dispatch) {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', url);

  /* Upload finished */
  xhr.onload = () => {
    if (xhr.status === 200) {
      dispatch({type: 'UPLOAD_COMPLETE'});
    } else {
      dispatch({type: 'ERROR', status: 'error', description: 'Upload failed'});
      dispatch({type: 'RESET_TILER'});
    }
  };

  /*  Upload connection error */
  xhr.onerror = () => {
    dispatch({type: 'ERROR', status: 'error', description: 'Upload failed'});
    dispatch({type: 'RESET_TILER'});
  };

  /* On upload progress */
  xhr.upload.onprogress = e => {
    if (e.lengthComputable) {
      const progress = (e.loaded / e.total) * 100;
      dispatch({type: 'SET_UPLOAD_PROGRESS', progress});
    }
  };

  // Prevents chrome sending header by default. This header results in 403 response.
  xhr.setRequestHeader('Content-Type', '');
  xhr.send(file);
}

// function scrubFilename(filename) {
//   return filename.replace(/[^\w\d_\-\.]+/gi, '');
// }
