export default (state = {}, action) => {

  if (action.type === 'API_REQUEST_COMPLETE') {

    switch (action.id) {

      case 'SET_SITE_RISKS':

        if (action.status !== 'success') {
          return state;
        }

        let siteRisksData = action.data['data'];

        let formattedSiteRisks = {};
        siteRisksData.forEach(function(risk) {
          formattedSiteRisks[risk['id']] = risk['attributes']['name'];
        });

        return {
          ...state,
          siteRisks: formattedSiteRisks,
        };

      default:
        return state;

    }
  }

  return state;
};
