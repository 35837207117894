import React from "react";

import Text from "./Text";

export default () => {
  return (
    <div className="unauthorised">
      <h1>
        <Text>Unauthorised</Text>
      </h1>
      <p>
        <Text>
          You do not have access to this application. Please contact your site
          administrator
        </Text>
      </p>
    </div>
  );
};
