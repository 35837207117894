export default () => {
  return {
    overlays: {
      overlays: {},
      geoAreasByAreaCode: null,
      mapBounds: null,
      visibleDepths: ['Surface'],
    },
    mapTiler: {
      state: 'WAITING',
      coords: null,
      uploadUrl: null,
    },
    geoAreaEditing: {
      enableNameEditing: false,
      enableDepthEditing: false,
      riskProfile: null,
    },
    physical: {
      physicalSites: [],
      physicalLocations: [],
      byId: {},
    },
    geoAreas: {
      byId: {},
      hierarchy: [],
      publishedIds: [],
      visibleIds: [],
      hierarchyBySiteId: {},
      siteLevelsById: {},
      sites: [],
      openedIds: [],
      selectedSite: false,
    },
    goto: {
      hierarchy: [],
    },
    customGeoArea: {
      mode: false,
      selectedTermId: null,
      coords: [],
      params: {},
      locationHierarchy: [],
      createType: 'physicalLocation',
    },
    risks: {
      siteRisks: {},
    },
    ui: {
      width: 768,
      isMobile: false,
      showNavMenu: false,
      showFilterMenu: false,
      navMenuEnabled: false,
      filterMenuEnabled: true,
    },
    language: {
      locale: 'en',
      t: {},
    },
    importExport: {
      importData: null,
      importFileUploaded: false,
      importOverwrite: false,
      importingProgress: false,
    },
    auth: {},
    modal: false,
    loading: {},
  };
};
