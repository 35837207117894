import config from '../Config';
import async from 'async';
import utf8 from 'utf8';

/*
 * Any API ID that requires a refresh of data - ie recalling the /hierarchy
 * endpoint
 */
const isReloadAction = action =>
  action.type === 'API_REQUEST_COMPLETE' &&
  ['BULK_IMPORT', 'CREATE_SITE', 'UPDATE_GEO_AREA', 'CREATE_GEO_AREA', 'DELETE_OVERLAY', 'UPDATE_OVERLAY', 'DELETE_GEO_AREA'].indexOf(
    action.id,
  ) > -1;

/*
 * Since all API requests are basically "listen for this action, then
 * make an API request", it's been abstracted into an array of arrays,
 * where each child arrays first element is the test of whether to execute
 * an API request, and the second element is a function that returns a
 * parameter object for an API request.
 *
 * Reducers can then respond to completed API requests by listening for an
 * action with a type of API_REQUEST_COMPLETE and an ID matching
 * the passed parameters from this listeners list
 *
 * Note also the AUTH_SET action is basically an "init" event, so anything
 * that you want to trigger on load should use this.
 */
const listeners = [
  [
    action => action.type === 'AUTH_SET',
    (action, state) => ({
      path: '/v1/crm/users/company/get',
      requestType: 'GET',
      reqParams: {
        uuid: state.auth["custom:forwood_uuid"],
        corporate_scope: '1',
      },
      id: 'COMPANY',
      api: 'reporting',
      errorMessage: 'Cannot get company',
    }),
  ],

  // Called after getting the company the user belongs to
  [
    action =>
      (action.type === 'API_REQUEST_COMPLETE' &&
        action.id === 'COMPANY' &&
        action.data),
    (action, state) => ({
      path: '/v1/report/company_site',
      requestType: 'GET',
      reqParams: {
        company_structure_id: state.auth.company[Object.keys(state.auth.company)].term_id,
      },
      id: 'PHYSICAL_SITES',
      api: 'reporting',
      errorMessage: 'Cannot get sites',
    }),
  ],

  // Hierarchy data refresh
  // - Called after getting the company sites (initial load)
  // - OR after any 'reload' defined action has completed
  [
    action =>
      (
        (action.type === 'API_REQUEST_COMPLETE' && action.id === 'PHYSICAL_SITES' ) ||
        ( isReloadAction(action) )
      ),
    (action, state) => {

      return {
        path: '/hierarchy',
        requestType: 'GET',
        id: 'HIERARCHY',
        errorMessage: 'Cannot get Goto GeoAreas',
      };
    },
  ],
  [
    action => action.type === 'SET_SELECTED_SITE',
    (action, state) => ({
      path: '/v2/report/physicallocation',
      requestType: 'GET',
      reqParams: {
        site: state.geoAreas.selectedSite,
      },
      id: 'PHYSICAL_LOCATIONS',
      api: 'reporting',
      errorMessage: 'Cannot get location hierarchy',
    }),
  ],


  // Set Site Risks operates as an adhoc request triggered by front-end when editing GeoAreas
  [
    action => action.type === 'SET_SITE_RISKS',
    (action, state) => ({
      path: '/v1/crm/risks/get',
      requestType: 'GET',
      reqParams: {
        company_id: state.auth.company[Object.keys(state.auth.company)].term_id,
        site_id: action.site_id,
      },
      id: 'SET_SITE_RISKS',
      api: 'reporting',
      errorMessage: 'Cannot get Site Risks',
    }),
  ],

  [
    action => action.type === 'UPDATE_GEO_AREA',
    (action, state) => ({
      path: '/geo-area',
      requestType: 'PUT',
      id: 'UPDATE_GEO_AREA',
      errorMessage: 'Cannot update GeoArea',
      body: {
        physicalLocationId: state.customGeoArea.id,
        bounds: JSON.stringify(state.customGeoArea.bounds),
        geoJson: JSON.stringify({
          geometry: {
            type: 'Polygon',
            coordinates: [state.customGeoArea.coords.map(c => [c[1], c[0]])]
          },
        }),
      },
    }),
  ],
  [
    action => action.type === 'UPDATE_GEO_AREA_NAME',
    (action, state) => ({
      path: '/geo-area',
      requestType: 'PUT',
      id: 'UPDATE_GEO_AREA_NAME',
      errorMessage: "Couldn't update GeoArea name",
      body: {
        physicalLocationId: action.physicalLocationId,
        name: utf8.encode(action.title),
      },
    }),
  ],
  [
    action => action.type === 'UPDATE_GEO_AREA_DEPTH',
    (action, state) => ({
      path: '/geo-area',
      requestType: 'PUT',
      id: 'UPDATE_GEO_AREA_DEPTH',
      errorMessage: "Couldn't update GeoArea depth",
      body: {
        physicalLocationId: action.physicalLocationId,
        depth: action.depth,
      },
    }),
  ],
  [
    action => action.type === 'UPDATE_GEO_AREA_RISK_PROFILE',
    (action, state) => ({
      path: '/geo-area',
      requestType: 'PUT',
      id: 'UPDATE_GEO_AREA_RISK_PROFILE',
      errorMessage: "Couldn't update GeoArea Critical Risk Profile",
      body: {
        physicalLocationId: action.physicalLocationId,
        critical_risk_ids: action.criticalRisks,
      },
    }),
  ],
  [
    action => action.type === 'BULK_IMPORT',
    (action, state) => ({
      path: '/import',
      requestType: 'POST',
      id: 'BULK_IMPORT',
      errorMessage: 'An error occured whilst importing GeoAreas',
      successMessage:
        'You successfully imported ' +
        state.importExport.importData.length +
        ' areas',
      body: state.importExport.importData,
    }),
  ],
  [
    action => action.type === 'EXPORT',
    (action, state) => ({
      path: '/export',
      requestType: 'GET',
      id: 'EXPORT',
      errorMessage: 'An error occured whilst exporting GeoAreas',
    }),
  ],
  [
    action => action.type === 'TOGGLE_GEO_AREA_PUBLISH',
    (action, state) => ({
      path: '/geo-area',
      requestType: 'PUT',
      id: 'PUBLISH_GEO_AREA',
      body: {
        physicalLocationId: action.id,
        published: !state.geoAreas.byId[action.id].published,
      },
      errorMessage: 'Cannot publish GeoArea',
      successMessage: 'GeoArea published successfully',
    }),
  ],
  [
    action => action.type === 'SAVE_CUSTOM_GEO_AREA',
    (action, state) => {
      const id = state.customGeoArea.id;
      const physicalLocation = state.physical.byId[id];

      const siteId =
        state.customGeoArea.type === 'PHYSICAL_LOCATION'
          ? state.geoAreas.selectedSite
          : id;

      return {
        path: '/geo-area',
        requestType: 'POST',
        errorMessage: 'Cannot create GeoArea',
        successMessage: 'You successfully created a new GeoArea',
        id: 'CREATE_GEO_AREA',
        body: {
          physicalLocationId: id,
          site_id: siteId,
          name: utf8.encode(physicalLocation.name),
          geoJson: JSON.stringify({
            type: 'Feature',
            properties: {
              name: utf8.encode(physicalLocation.name),
            },
            geometry: {
              type: 'Polygon',
              coordinates: [state.customGeoArea.coords.map(c => [c[1], c[0]])],
            },
          }),
        },
      };
    },
  ],
  /* MAP TILING */

  /*
   * THis POST request is triggered by middleware/createOverlay.js
   * See there for info about why this is necessary.
   */
  [
    action => action.type === 'UPLOAD_STARTED',
    (action, state) => ({
      path: '/create-overlay',
      requestType: 'POST',
      body: {
        userId: state.auth.username.split('%40').join('at'),
        geoAreas: state.mapTiler.geoAreas,
        fileType: action.file.name.substring(action.file.name.indexOf('.') + 1),
      },
      id: 'CREATE_OVERLAY',
      api: 'overlays',
      errorMessage:
        'Cannot upload map images at this time. Please reload the page and try again.',
    }),
  ],
  /*
   * This is the generic request that gets all active overlays
   * Triggers:
   * after auth,
   * after validation confirmation of a new overlay completes,
   * and after deleting an overlay completes
   */
  [
    action =>
      action.type === 'AUTH_SET' || action.type === 'CONFIRM_TILER_TILES' ||
      (action.type === 'API_REQUEST_COMPLETE' && action.id === 'UPDATE_OVERLAY') ||
      (action.type === 'API_REQUEST_COMPLETE' && action.id === 'DELETE_OVERLAY')
    ,
    (action, state) => ({
      path: `/mapoverlays`,
      reqParams: {
        state: 'READY',
      },
      requestType: 'GET',
      id: 'GET_OVERLAYS',
    }),
  ],
  [
    action => action.type === 'AUTH_SET',
    (action, state) => ({
      path: `/mapoverlays`,
      reqParams: {
        userId: state.auth.username.split('%40').join('at'),
        state: 'PROCESSING,ERROR,APPROVAL,READY,CANCELLED',
      },
      requestType: 'GET',
      id: 'USER_OVERLAY',
    }),
  ],
  [
    action =>
      (action.type === 'API_REQUEST_COMPLETE' &&
        action.id === 'USER_OVERLAY' &&
        action.data &&
        action.data.state === 'PROCESSING') ||
      (action.type === 'API_REQUEST_COMPLETE' &&
        action.id === 'SET_OVERLAY_PROCESSING'),
    (action, state) => ({
      path: `/mapoverlays`,
      reqParams: {
        userId: state.auth.username.split('%40').join('at'),
        state: 'PROCESSING,ERROR,APPROVAL,READY,CANCELLED',
      },
      requestType: 'GET',
      timeout: 10000,
      id: 'USER_OVERLAY',
    }),
  ],
  [
    action => action.type === 'UPLOAD_COMPLETE',
    (action, state) => ({
      path: '/mapoverlay',
      requestType: 'PUT',
      id: 'SET_OVERLAY_PROCESSING',
      body: {
        state: 'PROCESSING',
        id: state.mapTiler.id,
      },
    }),
  ],
  [
    action => action.type === 'ERROR_RECEIVED',
    (action, state) => ({
      path: '/mapoverlay',
      requestType: 'PUT',
      id: 'ERROR_RECEIVED',
      body: {
        state: 'CANCELLED',
        id: state.mapTiler.id,
      },
    }),
  ],
  [
    action => action.type === 'DISCARD_TILER_TILES',
    (action, state) => ({
      path: `/mapoverlay`,
      requestType: 'PUT',
      id: 'UPDATE_OVERLAY',
      body: {
        id: state.mapTiler.id,
        state: 'CANCELLED',
      },
      errorMessage:
        'There was a problem discarding the map image. Please try again',
      successMessage:
        "You successfully discarded the map image. It won't appear on any maps.",
    }),
  ],
  [
    action => action.type === 'CONFIRM_TILER_TILES',
    (action, state) => ({
      path: `/mapoverlay`,
      requestType: 'PUT',
      id: 'UPDATE_OVERLAY',
      body: {
        id: state.mapTiler.id,
        state: 'READY',
      },
      errorMessage:
        'There was a problem saving the map image. Please try again',
      successMessage:
        'Map images saved successfully. Images will start appearing in map views in the next few minutes',
    }),
  ],
  [
    action => action.type === 'DELETE_OVERLAY',
    (action, state) => ({
      path: '/geo-area/'+action.id+'/mapoverlay/'+action.mapOverlayId,
      requestType: 'DELETE',
      id: 'DELETE_OVERLAY',
      errorMessage: 'Cannot Delete Overlay',
      successMessage: 'Map Overlay Deleted Successfully'
    }),
  ],
  [
    action => action.type === 'DELETE_GEO_AREA',
    (action, state) => ({
      path: '/geo-area/'+action.id,
      requestType: 'DELETE',
      id: 'DELETE_GEO_AREA',
      errorMessage: 'This GeoArea could not be deleted',
      successMessage: 'GeoArea deleted successfully'
    }),
  ],
];

export default store => next => action => {
  next(action);

  const state = store.getState();

  /*
   * Run through each listener above, checks that the first element returns
   * truthy, then runs an API request for each that passes
   */
  listeners
    .filter(([shouldTrigger]) => shouldTrigger(action, state))
    .forEach(([shouldTrigger, request]) => makeRequest(request(action, state)));

  /*
   * The notifications middleware listens for this and displays an error
   * message in the top right-hand corner
   */
  function handleRequestError(id, description) {
    store.dispatch({
      type: 'API_REQUEST_COMPLETE',
      status: 'error',
      id,
      description,
    });
  }

  /*
   * This function makes the API request.
   * It triggers API_REQUEST_STARTED straight away, which is what allows
   * the loading reducer to know which endpoints have a request pending.
   *
   * The response is sent with a API_REQUEST_COMPLETE action, hook into
   * this elsewhere to listen for API call results
   */
  function makeRequest(requestParams) {
    const {
      path,
      requestType,
      body,
      id,
      errorMessage,
      isFile,
      api = 'geojson',
      successMessage,
      reqParams = {},
      timeout = 0,
    } = requestParams;

    store.dispatch({type: 'API_REQUEST_STARTED', id});

    const state = store.getState();
    if (state.auth.unauthorised) return;

    if (!state.auth.jwtToken) {
      handleRequestError(
        id,
        'Invalid user session. Please reload the page to sign in.',
      );
    }

    const baseUrl = config.api_urls[api];
    const url = `${baseUrl}${path}${toGetParams(reqParams)}`;
    const headers = new Headers();
    headers.append('Authorization', state.auth.jwtToken);
    headers.append('Content-Type', 'application/json; charset=utf-8');

    /*
     * This if clause is ghastly. Might be worth putting into a separate
     * middleware.
     */
    if (id === 'BULK_IMPORT') {
      let importGroup = state.auth.username + new Date().getTime();
      let chunk = 5;
      let requestArray = [];

      for (let i = 0, j = body.length; i < j; i += chunk) {
        requestArray.push(callback => {
          fetch(url, {
            method: requestType,
            body: JSON.stringify({
              data: body.slice(i, i + chunk),
              truncateAreas: state.importExport.importOverwrite,
              importGroup: importGroup,
            }),
            headers,
          })
            .then(res => res.json())
            .then(data => {
              callback(null, 'one');
            });
        });
      }

      async.parallelLimit(requestArray, 3, function(err, results) {
        //Finalize imports
        fetch(baseUrl + '/import/finalize', {
          method: 'PUT',
          body: JSON.stringify({importGroup: importGroup}),
          headers,
        });

        store.dispatch({
          type: 'API_REQUEST_COMPLETE',
          status: 'success',
          description: successMessage,
          id,
        });
      });
    } else {
      /*
       * Use setTimeout to delay a request, based on timeout parameter passed
       * in. This allows the overlays to poll every 10 seconds.
       */
      setTimeout(() => {
        fetch(url, {
          method: requestType,
          body: isFile ? body : JSON.stringify(body) || null,
          headers,
        })
          .then(res => {
            if (res.status !== 200) {
              handleRequestError(id, errorMessage);
              throw new Error('API Request failed');
            }

            return res.json();
          })
          .then(data => {

            /**
             * Highjacking the hierarchy data by removing sites not under the user's company
             * Ideally, this should be not here, and should be placed outside this function
             */
            if(id === 'HIERARCHY') {

              // Get Company Sites
              let physicalSitesId = state.physical.physicalSites.map((site) => parseInt(site.term_id));

              // Set site hierarchy to include only relevant sites
              data = data.map((site) => {

                if(physicalSitesId.includes(site.site_id)){
                  return site;
                }

                return undefined;

              }).filter((item) => item );
            }

            store.dispatch({
              type: 'API_REQUEST_COMPLETE',
              status: 'success',
              description: successMessage,
              id,
              data,
            });
          })
          .catch(e => console.error(e));
      }, timeout);
    }
  }
};

/*
 * Converts an object to a query parameter URL string
 */
function toGetParams(obj) {
  return Object.keys(obj).reduce((str, k, i) => {
    const token = i === 0 ? '?' : '&';
    return str + `${token}${k}=${obj[k]}`;
  }, '');
}
