import React from 'react';
import {Icon, Checkbox} from 'antd';

import Text from './Text';
import MiniLoader from './MiniLoader';

/*
 * Gets the maximum height of the sidebar - we want the maximum height possible
 * to reduce scrolling, but to prevent overflow
 */
const getMaxHeight = (windowHeight, isMobile) => {
  // height of all other elements on page - hardcoded
  const otherElementsHeight = isMobile ? 330 : 430;
  return windowHeight - otherElementsHeight;
};

export default props => {
  const {
    hierarchy,
    visibleIds,
    publishedIds,
    toggleOpened,
    height,
    isMobile,
    setVisibility,
    openedIds,
  } = props;

  // const title = (
  //   <Text>
  //     If checked, no areas below/above the selected area will be displayed
  //   </Text>
  // );

  const displayTreeItem = treeItem(
    publishedIds,
    visibleIds,
    openedIds,
    toggleOpened,
    setVisibility,
  );
  const maxHeight = getMaxHeight(height, isMobile);

  return !!hierarchy ? (
    <React.Fragment>
      <h4>
        <Text>Select GeoAreas</Text>
      </h4>
      <div className="scroll-overflow" style={{maxHeight}}>
        <ul className="hierarchy-tree">{[hierarchy].map(displayTreeItem)}</ul>
      </div>
    </React.Fragment>
  ) : (
    <MiniLoader text="Loading site hierarchy" />
  );
};

/*
 * Blue globe published, grey unpublished
 */
// function NodeIcon({isPublished}) {
//   const title = <Text>{isPublished ? 'Published' : 'Not Published'}</Text>;
//   return (
//     <Tooltip placement="top" title={title}>
//       <Icon
//         type="global"
//         theme="outlined"
//         style={{color: isPublished ? '#023dff' : 'rgba(76,76,76,0.4)'}}
//       />
//     </Tooltip>
//   );
// }

/*
 * Recursively gets all child ID's of a selected geoarea
 */
function getIdsOfChildren(geoArea) {
  const childIds = geoArea.children.map(g => g.id);
  const childGeoAreas = geoArea.children.reduce((arr, geoArea) => {
    return [...arr, ...getIdsOfChildren(geoArea)];
  }, []);

  return [...childIds, ...childGeoAreas];
}

/*
 * A function that returns a function, to be partially applied from within
 * the above component, and to be called directly on subsequent recursive
 * calls
 */
function treeItem(
  publishedIds,
  visibleIds,
  openedIds,
  toggleOpened,
  setVisibility,
) {
  return function displayTreeItem(geoArea) {
    const isPublished = publishedIds.indexOf(geoArea.id) > -1;
    const isVisible = visibleIds.indexOf(geoArea.id) > -1;
    const isOpen = openedIds.indexOf(geoArea.id) > -1;
    const hasChildren = geoArea.children.length > 0;
    //const icon = <NodeIcon isPublished={isPublished} />;

    const onChange = e => {
      const ids = [geoArea.id, ...getIdsOfChildren(geoArea)];
      const isChecked = e.target.checked;
      setVisibility(ids, isChecked);
    };

    return (
      <li key={geoArea.id}>
        <span className="tree-dropdown" onClick={e => toggleOpened(geoArea.id)}>
          {hasChildren && <Icon type={isOpen ? 'caret-down' : 'caret-right'} />}
        </span>
        <span className="tree-checkbox">
          <Checkbox onChange={onChange} checked={isVisible} />
        </span>
        <span className={`tree-icon ${isPublished ? 'published' : ''}`}>
          <Icon type="global" />
        </span>
        <span className="tree-title">{geoArea.name}</span>
        {hasChildren && isOpen && (
          <ul className="hierarchy-tree">
            {geoArea.children.map(displayTreeItem)}
          </ul>
        )}
      </li>
    );
  };
}
