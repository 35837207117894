import React from 'react';
import {Spin} from 'antd';

import Text from './Text';

/*
 * A simple loader to display a loading spinner, with text if desired.
 */
export default ({text}) => {
  return (
    <div className="mini-loader">
      <Spin />
      <span>{text ? <Text>{text}</Text> : null}</span>
    </div>
  );
};
