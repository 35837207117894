import {connect} from 'react-redux';

import Component from '../Components/ResponsiveLayout';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setWidth(width) {
      dispatch({type: 'UI_SET_WIDTH', width});
    },
    setHeight(height) {
      dispatch({type: 'UI_SET_HEIGHT', height});
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
