/*
 * This is for editing the values of a geoarea. If you want to edit the coordinates
 * of the polygon look in reducers/customGeoArea.js
 *
 * These are all basic methods used to update temporary state while the user is
 * updating a value, such as typing in the depth box.
 */
export default (state = {}, action) => {
  switch (action.type) {
    /*
     * Reset temporary field values when update API request is complete
     * - also done when deletion of a MapOverlay has completed successfully
     * (new functionality triggered from within this same GeoArea edit window)
     */
    case 'API_REQUEST_COMPLETE':
      if (
        action.id === 'UPDATE_GEO_AREA_DEPTH' ||
        action.id === 'UPDATE_GEO_AREA_RISK_PROFILE' ||
        action.id === 'UPDATE_GEO_AREA_NAME' ||
        action.id === 'UPDATE_OVERLAY' ||
        action.id === 'DELETE_OVERLAY' ||
        action.id === 'DELETE_GEO_AREA'
      ) {
        return {
          ...state,
          enableDepthEditing: false,
          enableNameEditing: false,
          isConfirming: false,
          tempCriticalRisks: [],
        };
      }

      return state;

    case 'TOGGLE_NAME_EDITING':
      return {
        ...state,
        enableNameEditing:
          state.enableNameEditing === action.areaCode ? false : action.areaCode,
      };

    case 'TOGGLE_DEPTH_EDITING':
      return {
        ...state,
        enableDepthEditing:
          state.enableDepthEditing === action.areaCode
            ? false
            : action.areaCode,
      };

    case 'ENABLE_CRITICAL_RISK_EDITING':
      return {
        ...state,
        editingCriticalRisk: action.id,
      };

    case 'DISABLE_CRITICAL_RISK_EDITING':
      return {
        ...state,
        editingCriticalRisk: false,
        tempCriticalRisks: [],
      };

    case 'SET_TEMP_CRITICAL_RISKS':
      return {
        ...state,
        tempCriticalRisks: action.criticalRisks,
      };

    case 'ENABLE_TITLE_EDITING':
      return {
        ...state,
        editingTitle: action.id,
        tempTitle: '',
      };

    case 'DISABLE_TITLE_EDITING':
      return {
        ...state,
        editingTitle: false,
        tempTitle: '',
      };

    case 'SET_TEMP_TITLE':
      return {
        ...state,
        tempTitle: action.title,
      };

    case 'ENABLE_DEPTH_EDITING':
      return {
        ...state,
        editingDepth: action.id,
        tempDepth: '',
      };

    case 'DISABLE_DEPTH_EDITING':
      return {
        ...state,
        editingDepth: false,
        tempDepth: '',
      };

    case 'SET_TEMP_DEPTH':
      return {
        ...state,
        tempDepth: action.depth,
      };

    case 'UPDATE_GEO_AREA_RISK_PROFILE':
      return {
        ...state,
        editingCriticalRisk: false,
      };

    case 'UPDATE_GEO_AREA_NAME':
      return {
        ...state,
        editingTitle: false,
      };

    case 'UPDATE_GEO_AREA_DEPTH':
      return {
        ...state,
        editingDepth: false,
      };

    case 'CONFIRM_OVERLAY_DELETING':
      return {
        ...state,
        isConfirming: true,
        deletingOverlay: action.id
      };

    case 'UNCONFIRM_OVERLAY_DELETING':
      return {
        ...state,
        isConfirming: false,
        deletingOverlay: ''
      };

    case 'CLEAR_GEO_AREA_POPUP_STATE':
      return {
        ...state,
        mode: false,
        params: {},
        coords: [],
      };

    default:
      return state;
  }
};
