import React from 'react';
import {Spin} from 'antd';

import Text from './Text';

export default ({show}) => {
  return show ? (
    <div className="loading">
      <div>
        <Spin size="large" />
        <h2>
          <Text>Loading</Text>
          ...
        </h2>
      </div>
    </div>
  ) : null;
};
