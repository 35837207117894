export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_IMPORT_DATA':
      let processedData = action.data.filter(item => {
        return item[0] !== 'physicalLocationId' && item.length >= 2;
      });

      processedData = processedData.map((area) => {
          let countCriticalRisks = area.length - 6;
          let arrayKeys = Array.apply(null, {length: countCriticalRisks}).map(Number.call, Number);
          let criticalRiskIdArray = [];

          arrayKeys.map((arrayKey) => {
            if (area[6 + arrayKey] !== "") {
              criticalRiskIdArray.push(area[6 + arrayKey]);
            }
            return true;
          });

          area[6] = criticalRiskIdArray;

          return area
      });
      
      return {
        ...state,
        importData: processedData,
        importFileUploaded: true,
      };

    case 'ENABLE_IMPORT_OVERWRITE':
      return {
        ...state,
        importOverwrite: !state.importOverwrite,
      };

    case 'RESET_IMPORT':
      return {
        ...state,
        importData: null,
        importFileUploaded: false,
        importOverwrite: false,
      };

    case 'BULK_IMPORT':
      return {
        ...state,
        importingProgress: true,
      };

    case 'API_REQUEST_COMPLETE':
      if (action.id === 'BULK_IMPORT' && action.status === 'success') {
        return {
          ...state,
          importingProgress: false,
          importData: null,
          importFileUploaded: false,
          importOverwrite: false,
        };
      }

      if (action.id === 'EXPORT' && action.status === 'success') {
        let blob = new Blob([action.data.data]);
        if (window.navigator.msSaveOrOpenBlob)
          // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
          window.navigator.msSaveBlob(blob, 'filename.csv');
        else {
          let a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob, {type: 'text/plain'});
          a.download = 'area_export.csv';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
      return state;

    default:
      return state;
  }
};
