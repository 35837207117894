/*
 * We are using the amazon-cognito-identity-js package instead of the full
 * AWS Amplify library as it is much lighter, saving us about 300kb in bundle
 * size. This is because AWS Amplify library is not setup to allow
 * "tree shaking". They are working on this so at some point in the future
 * it could be worth switching back to AWS Amplify. This library though is
 * what AWS Amplify uses underneath
 */
import {CognitoUserPool, CookieStorage} from 'amazon-cognito-identity-js';
import Config from '../Config';

const poolConfig = {
  ClientId: Config.Auth.userPoolWebClientId,
  UserPoolId: Config.Auth.userPoolId,
  Storage: new CookieStorage(Config.Auth.cookieStorage),
};

/*
 * This code is fired instantly, before even the Redux code has initted, since
 * the cookie will be there or not, this is just to make it quicker in the case
 * of no user session
 */
const pool = new CognitoUserPool(poolConfig);
const user = pool.getCurrentUser();

if (!user) redirect();

function redirect() {
  window.location.href = Config.forwood_id_url;
}

export default store => next => action => {
  next(action);

  if (!user) return;

  if (action.type === 'INIT' || action.type === 'AUTH_REFRESH') {
    user.getSession((err, result) => {
      if (err) redirect();

      const payload = result.idToken.payload;
      const groups = payload['cognito:groups'];
      const isAdmin = groups
        ? groups.indexOf('ForwoodIdAdmin') > -1 ||
          groups.indexOf('ForwoodAdmin') > -1
        : false;

      const auth = {
        jwtToken: result.idToken.jwtToken,
        isAdmin,
        username: payload.email,
        ...payload,
      };

      /* User must have GeoeditorAccess role in Forwood ID to view app */
      if (!groups || !groups.includes('GeoeditorAccess')) {
        store.dispatch({type: 'SET_UNAUTHORISED'});
      }

      setTimeout(() => store.dispatch({type: 'AUTH_REFRESH'}), 300000);

      const type = action.type === 'INIT' ? 'AUTH_SET' : 'AUTH_UPDATE';
      store.dispatch({type, ...auth});
    });
  } else if (action.type === 'AUTH_LOGOUT') {
    const user = pool.getCurrentUser();
    store.dispatch({type: 'AUTH_CLEAR'});
    user.signOut();
    window.location.reload();
  }
};
