/*
 * This contains the data of all geoareas. This means the geoareas displayed
 * on the map as well as parts like the site selector, the overlay parent
 * selector etc.
 *
 * The philosophy behind this was to do all the tricky data wrangling stuff
 * here to allow the components themselves to be as simple as possible.
 */

import { removeFromArray} from '../utils/utils';

/*
 * Same as the default state in store/initial - allows easy reset
 */
const EMPTY_STATE = () => ({
  byId: {},
  hierarchy: [],
  publishedIds: [],
  hierarchyBySiteId: {},
  siteLevelsById: {},
});

export default (state = {}, action) => {
  switch (action.type) {
    /*
     * The hierarchy endpoint contains all geoareas in a tree-like structure.
     * We start the reducer with the default EMPTY_STATE() to ensure
     * a clean update, since this function can be called multiple times because
     * updates of geoareas trigger a new request to hierarchy endpoint
     */
    case 'API_REQUEST_COMPLETE':
      if (action.status === 'error' || action.id !== 'HIERARCHY') return state;
      const hierarchy = action.data;

      const newState = hierarchy.reduce(dataToState(0), EMPTY_STATE());
      const sites = action.data.map(s => ({name: s.name, id: s.site_id}));

      return {
        ...state,
        ...newState,
        hierarchy,
        sites,
      };

    /*
     * Updates each geoarea in place with critical risk info
     */
    case 'UPDATE_GEO_AREA_RISK_PROFILE':
      return updateGeoArea(state, action.physicalLocationId, {
        critical_risks: action.criticalRisks,
      });

    case 'UPDATE_GEO_AREA_NAME':
      return updateGeoArea(state, action.physicalLocationId, {
        name: action.title,
      });

    case 'UPDATE_GEO_AREA_DEPTH':
      return updateGeoArea(state, action.physicalLocationId, {
        depth: action.depth,
      });

    case 'SET_VISIBILITY':
      return {
        ...state,
        visibleIds: action.isChecked
          ? removeDuplicates([...state.visibleIds, ...action.ids])
          : state.visibleIds.filter(id => action.ids.indexOf(id) === -1),
      };

    case 'TOGGLE_GEO_AREA_PUBLISH':
      return {
        ...state,
        publishedIds: toggleInArray(state.publishedIds, action.id),
      };

    case 'SET_SELECTED_SITE':
      // Added extra protection here for weird state situations.. just return state and empty site if no site passed with action
      if (!action.site || action.site === 'undefined') {
        return {
          ...state,
          selectedSite: false
        }
      }

      return {
        ...state,
        selectedSite: action.site,
        visibleIds: flatMap(
          state.hierarchyBySiteId[action.site],
          item => item.id,
        ),
      };

    case 'TOGGLE_OPENED':
      return {
        ...state,
        openedIds: toggleInArray(state.openedIds, action.id),
      };

    case 'REMOVE_GEO_AREA_FROM_STATE':
      return {
        ...state,
        visibleIds: removeFromArray(state.visibleIds, action.id),
        publishedIds: removeFromArray(state.publishedIds, action.id),
        openedIds: removeFromArray(state.openedIds, action.id),
        selectedSite: false,
      };

    default:
      return state;
  }
};

function removeDuplicates(arr) {
  const asObject = arr.reduce((obj, item) => {
    obj[item] = item;
    return obj;
  }, {});

  return Object.keys(asObject).map(k => asObject[k]);
}

/*
 * Runs through each hierarchical level returning a flat array of ids
 */
function flatMap(siteGeoArea, fn) {
  const reduce = (a, item) => {
    return [...a, fn(item), ...item.children.reduce(reduce, [])];
  };

  return [siteGeoArea].reduce(reduce, []);
}

/*
 * This function does all the heavy lifting of setting our geoarea data
 * structure.
 */
function dataToState(depth, parents = []) {
  return (obj, item) => {
    const geoArea = formatGeoArea(item);
    const id = geoArea.id;
    const siteLevels = [...parents, geoArea.name];

    const update = {
      ...obj,
      byId: {
        ...obj.byId,
        [id]: geoArea,
      },
      siteLevelsById: {
        ...obj.siteLevels,
        [id]: siteLevels,
      },
      publishedIds: geoArea.published
        ? [...obj.publishedIds, id]
        : obj.publishedIds,
      hierarchyBySiteId:
        depth === 0
          ? {
              ...obj.hierarchyBySiteId,
              [geoArea.siteId]: geoArea,
            }
          : obj.hierarchyBySiteId,
    };

    return geoArea.children.reduce(dataToState(depth + 1, siteLevels), update);
  };
}

/*
 * Turns geojson string into a valid array of lat long coordinates
 */
function formatGeoArea(geoArea) {
  const geojson = JSON.parse(geoArea.geoJson).geometry;
  const coords = geojson.coordinates[0].map(c => [c[1], c[0]]);

  return {
    ...geoArea,
    coords,
    siteId: geoArea.site_id,
  };
}

/*
 * Looks up if item is in array. If it is, it removes it, if not it adds that item
 * to the array
 */
function toggleInArray(arr, value) {
  const index = arr.indexOf(value);
  if (index === -1) return arr.concat([value]);

  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

/*
 * Updates a geoarea using the given id across the entire geoAreas data
 * structure. Is pure, in that it takes a state as argument and returns
 * the full new state.
 */
function updateGeoArea(state, id, updateObj) {
  const updateHierarchy = geoArea => {
    const update = geoArea.id === id ? updateObj : {};
    return {
      ...geoArea,
      ...update,
      ...geoArea.children.map(updateHierarchy),
    };
  };

  const hierarchyArray = Object.keys(state.hierarchyBySiteId).map(
    k => state.hierarchyBySiteId[k],
  );

  return {
    ...state,
    byId: {
      ...state.byId,
      [id]: {
        ...state.byId[id],
        ...updateObj,
      },
    },
    sites: state.sites.map(s => {
      if (s.id !== id) return s;
      return {
        ...s,
        ...updateObj,
      };
    }),
    hierarchyBySiteId: hierarchyArray
      .map(updateHierarchy)
      .reduce((obj, geoArea) => {
        return {
          ...obj,
          [geoArea.id]: geoArea,
        };
      }, {}),
  };
}
