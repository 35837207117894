export default store => next => action => {
  next(action);
  if (action.type === 'AUTH_SET' ||
    (action.type === 'API_REQUEST_COMPLETE' &&
      action.id === 'HIERARCHY' &&
      action.status !== 'error')
  ) {
    setTimeout(() => store.dispatch({type: 'MAP_READY'}), 2000);
  }

  // Set map waiting when saving a new geo area
  if (action.type === 'SAVE_CUSTOM_GEO_AREA') {
    store.dispatch({type: 'MAP_WAIT'});
  }

  // Set map waiting when saving an edited geo area
  if (action.type === 'UPDATE_GEO_AREA') {
    store.dispatch({type: 'MAP_WAIT'});
  }

  // Set map waiting when deleting a geo area
  if (action.type === 'DELETE_GEO_AREA') {
    store.dispatch({type: 'MAP_WAIT'});
  }

};