export default (state = {}, action) => {
  switch (action.type) {
    case 'MAP_READY':

      return {
        ...state,
        mapReady: true,
      };

    case 'MAP_WAIT':

      return {
        ...state,
        mapReady: undefined,
      };

    default:
      return state;
  }
};
