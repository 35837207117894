let siteToSet = null;
let typeToSet = null;

/*
 * This middleware selects the correct GeoArea(s) for display after creation of a new GeoArea
 * Note that the post-processing kicks in only after the hierarchy data has completed its refresh.
 * Note the different post-handling for Site vs PhysicalLocation GeoArea creation types
 */
export default store => next => action => {
  next(action);

  if (action.type === 'SAVE_CUSTOM_GEO_AREA') {
    siteToSet = store.getState().customGeoArea.id;
    typeToSet = store.getState().customGeoArea.createType;
  }

  // When updating a GeoArea, force a new Goto (to deal with corrected co-ords on update)
  if (action.type === 'UPDATE_GEO_AREA') {
    let coords = store.getState().customGeoArea.coords;
    let name = store.getState().customGeoArea.name;
    store.dispatch({type: 'GOTO', goto: coords, name: name });
  }

  // When deleting a GeoArea, clear localStorage site to prevent selectedSite state issues
  if (action.type === 'DELETE_GEO_AREA') {
    store.dispatch({type: 'CLEAR_SELECTED_SITE', site: action.id});
    siteToSet = null;
    typeToSet = null;
  }

  if (
    action.type === 'API_REQUEST_COMPLETE' &&
    action.id === 'HIERARCHY' &&
    action.status !== 'error'
  ) {
    if (siteToSet) {
      if (typeToSet === 'physicalLocation') {
        store.dispatch({type: 'SET_PHYSICAL_LOCATION_SELECTION', value: siteToSet});
      } else {
        store.dispatch({type: 'SET_SELECTED_SITE', site: siteToSet});
      }
      siteToSet = null;
      typeToSet = null;
    }
  }
};
