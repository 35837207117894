import React from 'react';
import throttle from 'lodash/throttle';

/*
 * This has been built as a class rather than a "pure" function so that
 * we can access the React lifecycle methods "componentDidMount" (on init) and
 * "componentWillUnmount" (on destroyed).
 *
 * It works by listening for browser resize events, and setting the browser
 * width to the store, so components can work out if the user is on mobile or
 * not. This is like "media queries for Javascript".
 *
 * From a rendering point of view, this simply renders it's children.
 */
export default class ResponsiveLayout extends React.Component {
  componentDidMount() {
    this.saveViewportDimensions();
    window.addEventListener('resize', this.saveViewportDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.saveViewportDimensions);
  }

  /*
   * If a user drags their screen to resize it it will fire resize events
   * rapidly, causing every component that depends on the ui.width value in the
   * store to rerender each time. We get around this by using lodash's throttle,
   * which only fires the function at most once every 100ms.
   */
  saveViewportDimensions = throttle(() => {
    setTimeout(() => {
      this.props.setWidth(window.innerWidth);
      this.props.setHeight(window.innerHeight);
    }, 1000);
  }, 100);

  render() {
    return this.props.children;
  }
}
