import {notification} from 'antd';

export default store => next => action => {
  next(action);

  const translate = text => store.getState().language.t[text] || text;

  if (action.type === 'API_REQUEST_COMPLETE' || action.type === 'ERROR') {
    if (action.status && action.description) {
      notification[action.status]({
        message: translate(
          action.message ? action.message : `${action.status.toUpperCase()}`,
        ),
        description: translate(action.description),
      });
    }
  }
};
