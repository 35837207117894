import React from 'react';

import Text from './Text';

export default () => {
  return (
    <div id="legend">
      <h4>
        <Text>Legend</Text>
      </h4>
      <ul>
        <li>
          <span className="unpublished" />
          <Text>GeoArea</Text>
        </li>
        <li>
          <span className="edited" />
          <Text>GeoArea being edited</Text>
        </li>
      </ul>
    </div>
  );
};
