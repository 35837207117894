import React from 'react';
import {Dropdown, Button, Icon, Menu} from 'antd';

import Text from './Text';

/*
 * The dropdown language selector in the top right-hand corner of the screen
 */

const langMap = {
  en: 'English (English)',
  es: 'Spanish (Español)',
  fr: 'French (Français)',
  mn: 'Mongolian (Монгол хэл)',
  nb: 'Norwegian (Norsk)',
  nl: 'Dutch (Nederlands)',
  pt: 'Portuguese (Português)',
  ru: 'Russian (русский)',
  zh: 'Chinese, Simplified (简化字)',
  de: 'German (Deutsch)',
  it: 'Italian (Italiano)',
  is: 'Icelandic (Íslensku)',
  sr: 'Serbian (Српски)',
  fi: 'Finnish (Suomi)',
  id: 'Indonesian (Bahasa Indonesia)',
  ja: 'Japanese (日本語)',
  tr: 'Turkish (Türkçe)'
};

export default ({setLanguage, isMobile, locale}) => {
  const menu = (
    <Menu onClick={o => setLanguage(o.key)}>
      <Menu.Item key="en">
        <Text>English (English)</Text>
      </Menu.Item>
      <Menu.Item key="es">
        <Text>Spanish (Español)</Text>
      </Menu.Item>
      <Menu.Item key="fr">
        <Text>French (Français)</Text>
      </Menu.Item>
      <Menu.Item key="mn">
        <Text>Mongolian (Монгол хэл)</Text>
      </Menu.Item>
      <Menu.Item key="nb">
        <Text>Norwegian (Norsk)</Text>
      </Menu.Item>
      <Menu.Item key="nl">
        <Text>Dutch (Nederlands)</Text>
      </Menu.Item>
      <Menu.Item key="pt">
        <Text>Portuguese (Português, Brasil)</Text>
      </Menu.Item>
      <Menu.Item key="ru">
        <Text>Russian (русский)</Text>
      </Menu.Item>
      <Menu.Item key="zh">
        <Text>Chinese, Simplified (简化字)</Text>
      </Menu.Item>
      <Menu.Item key="de">
        <Text>German (Deutsch)</Text>
      </Menu.Item>
      <Menu.Item key="it">
        <Text>Italian (Italiano)</Text>
      </Menu.Item>
      <Menu.Item key="is">
        <Text>Icelandic (Íslensku)</Text>
      </Menu.Item>
      <Menu.Item key="sr">
        <Text>Serbian (Српски)</Text>
      </Menu.Item>
      <Menu.Item key="fi">
        <Text>Finnish (Suomi)</Text>
      </Menu.Item>
      <Menu.Item key="id">
        <Text>Indonesian (Bahasa Indonesia)</Text>
      </Menu.Item>
      <Menu.Item key="ja">
         <Text>Japanese (日本語)</Text>
      </Menu.Item>
      <Menu.Item key="tr">
         <Text>Turkish (Türkçe)</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      trigger={['click']}
      overlay={menu}
      overlayStyle={{zIndex: 100000}}>
      {isMobile ? (
        <Button style={{marginRight: 20}} size="large" shape="circle">
          <Icon type="global" style={{fontSize: 16}} />
        </Button>
      ) : (
        <Button size="large" style={{marginRight: 20}}>
          <Icon type="global" style={{fontSize: 16}} />{' '}
          <Text>{langMap[locale]}</Text>
        </Button>
      )}
    </Dropdown>
  );
};
