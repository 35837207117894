const SITE_KEY = 'forwoodsafety_geoeditor_site?version=2';
const LOCALE_KEY = 'forwoodsafety_geoeditor_locale';
const EXAMPLE_KEY = 'forwood_geoeditor_example';

/*
 * This stores all configuration values that we want the app to remember
 * every time a user returns.
 *
 * So we store their language, the selected site and whether they have
 * selected "Don't show this message again" box when creating a GeoArea
 */
export default store => next => action => {
  next(action);

  if (action.type === 'AUTH_SET') {
    const locale = window.localStorage.getItem(LOCALE_KEY);
    if (locale) store.dispatch({type: 'LANGUAGE_SET', locale});

    const example = window.localStorage.getItem(EXAMPLE_KEY);
    if (example) store.dispatch({type: 'EXAMPLE_SEEN'});
  }

  if (action.id === 'HIERARCHY' && action.type === 'API_REQUEST_COMPLETE') {
    const site = window.localStorage.getItem(SITE_KEY);
    if (site) store.dispatch({type: 'SET_SELECTED_SITE', site});
  }

  if (action.id === 'CREATE_GEO_AREA' &&
    action.type === 'API_REQUEST_COMPLETE') {
    window.localStorage.setItem(
      SITE_KEY,
      store.getState().geoAreas.selectedSite,
    );
  }

  if (action.type === 'SET_SELECTED_SITE') {
    window.localStorage.setItem(
      SITE_KEY,
      store.getState().geoAreas.selectedSite,
    );
    store.dispatch({id: 'SET_SITE_RISKS', type: 'SET_SITE_RISKS', site_id: store.getState().geoAreas.selectedSite});
  }

  // Delete the localStorage Site ONLY IF we have just deleted that same SiteId (i.e. we deleted the one currently set)
  if (action.type === 'CLEAR_SELECTED_SITE') {

    const deletedSite = parseInt(action.site);
    const storedSite = parseInt(window.localStorage.getItem(SITE_KEY));

    if (deletedSite === storedSite) {
      window.localStorage.removeItem(SITE_KEY);
    }
  }

  if (action.type === 'LANGUAGE_SET') {
    window.localStorage.setItem(LOCALE_KEY, action.locale);
  }

  if (action.type === 'EXAMPLE_SEEN') {
    if (store.getState().customGeoArea.exampleHideInFuture) {
      window.localStorage.setItem(EXAMPLE_KEY, 'true');
    }
  }
};
