import {connect} from 'react-redux';

import Component from '../Components/Layout';

const mapStateToProps = state => {
  return {
    isMobile: state.ui.isMobile,
    locale: state.language.locale,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
