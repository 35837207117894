import React from 'react';
import {Layout} from 'antd';

import Header from '../Containers/Header';
import FilterMenu from '../Containers/FilterMenu';

/*
 * The NavMenu component has been removed from here to save on bundle size.
 * Simply make the NavMenu variable be an import of Containers/NavMenu.js to
 * add a navigation menu to the right hand side.
 */

const {Footer, Content} = Layout;

/* JS Date.getYear() starts from 1900 for some reason */
const year = new Date().getYear() + 1900;

export default ({isMobile, children}) => {
  const content = (
    <Content
      style={{
        position: 'relative',
        margin: '16px 16px 0 16px',
        background: '#fff',
      }}>
      {children}
    </Content>
  );

  /* The antdesign <Layout /> component is best thought of as a "row".*/
  return (
    <Layout style={{height: '100%', width: '100%'}}>
      {isMobile ? (
        <Layout>
          <Header />
          <Layout style={{position: 'relative'}}>
            {content}
            <FilterMenu />
          </Layout>
        </Layout>
      ) : (
        <React.Fragment>
          <Header />
          <Layout>
            {content}
            <FilterMenu />
          </Layout>
          <Footer style={{textAlign: 'center'}}>
            Forwood Enterprises &copy; {year}
          </Footer>
        </React.Fragment>
      )}
    </Layout>
  );
};
