export default (state = {}, action) => {
  switch (action.type) {
    case 'UI_SET_WIDTH':
      return {
        ...state,
        width: action.width,
        isMobile: action.width <= 768,
      };

    case 'UI_SET_HEIGHT':
      return {
        ...state,
        height: action.height,
      };

    case 'UI_HIDE_NAV_MENU':
      return {
        ...state,
        showNavMenu: false,
      };

    case 'UI_TOGGLE_NAV_MENU':
      return {
        ...state,
        showNavMenu: !state.showNavMenu,
      };

    case 'UI_TOGGLE_FILTER_MENU':
      return {
        ...state,
        showFilterMenu: !state.showFilterMenu,
      };

    case 'UI_TOGGLE_COMPANY_SITE_FILTER':
      return {
        ...state,
        showCompanySiteFilter: action.showFilter,
        companySiteFilterLoading: false,
      };

    case 'UI_ENABLE_COMPANY_SITE_LOADING':
      return {
        ...state,
        companySiteFilterLoading: true,
      };

    case 'TOOLBAR_READY':
      return {
        ...state,
        toolbarReady: true,
      };

    default:
      return state;
  }
};
