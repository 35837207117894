import en from '../Translations/en.json';
import es from '../Translations/es.json';
import fr from '../Translations/fr.json';
import mn from '../Translations/mn.json';
import pt from '../Translations/pt.json';
import ru from '../Translations/ru.json';
import nb from '../Translations/nb.json';
import zh from '../Translations/zh.json';
import nl from '../Translations/nl.json';
import de from '../Translations/de.json';
import it from '../Translations/it.json';
import is from '../Translations/is.json';
import sr from '../Translations/sr.json';
import fi from '../Translations/fi.json';
import id from '../Translations/id.json';
import ja from '../Translations/ja.json';
import tr from '../Translations/tr.json';

const translations = {en, es, fr, mn, nb, nl, pt, ru, zh, de, it, is, sr, fi, id, ja, tr};

/*
 * Translations work by setting the translations files into the store that the
 * <Text /> component then reads
 */
export default (state = {}, action) => {
  switch (action.type) {
    case 'LANGUAGE_SET':
      return {
        ...state,
        locale: action.locale,
        t: translations[action.locale] || en,
      };

    default:
      return state;
  }
};
