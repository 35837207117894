/*
 * This is the logic for the map conversion tool.
 */
const DEFAULT_STATE = (siteHierarchy, id) => ({
  state: 'WAITING',
  coords: null,
  uploadUrl: null,
  siteHierarchy,
  id,
});

export default (state = {}, action) => {
  switch (action.type) {
    case 'API_REQUEST_COMPLETE':
      switch (action.id) {
        case 'CREATE_OVERLAY':
          if (action.status !== 'success') {
            return {
              ...state,
              state: 'WAITING',
            };
          }
          return {
            ...state,
            id: action.data.id,
            uploadUrl: action.data.signedUrl,
          };

        /*
         * This is replicating what we do in reducers/goto.js. We duplicate
         * it to prevent having a hidden dependency
         */
        case 'GOTO_HIERARCHY':
          if (action.status === 'error') return state;
          return {
            ...state,
            siteHierarchy: action.data,
          };

        case 'USER_OVERLAY':
          if (action.status === 'error') return state;
          if (!action.data) return state;

          if (
            action.data.state === 'READY' ||
            action.data.state === 'CANCELLED'
          ) {
            return DEFAULT_STATE(state.siteHierarchy);
          }

          const d = action.data;
          const coords = d.coords
            ? d.coords
                .replace(/[{()}]/g, '')
                .split(', ')
                .map(s => parseFloat(s))
            : null;

          const overlay = {
            ...d,
            coords: coords ? [coords[1], coords[0]] : null,
            type: d.type,
          };

          return {
            ...state,
            ...overlay,
          };

        default:
          return state;
      }

    case 'HIDE_MODAL':
      return {
        ...state,
        uploadUrl: null,
      };

    case 'SET_SELECTED_SITE':
      return {
        ...state,
      };

    case 'SET_OVERLAY_GEOAREAS':
      return {
        ...state,
        geoAreas: action.geoAreas,
      };

    case 'UPLOAD_STARTED':
      return {
        ...state,
        state: 'CREATING',
        file: action.file,
      };

    case 'SET_UPLOAD_PROGRESS':
      return {
        ...state,
        progress: action.progress,
        state: 'UPLOADING',
      };

    case 'UPLOAD_COMPLETE':
      return {
        ...state,
        state: 'PROCESSING',
      };

    case 'SET_TILER_COORDS':
      const coords = action.coords
        .replace('(', '')
        .replace(' ', '')
        .split(',')
        .map(s => parseFloat(s));

      return {
        ...state,
        coords: [coords[1], coords[0]],
      };

    case 'VIEW_TILER_IMAGE':
      return {
        ...state,
        viewed: true,
        validationOverlayVisible: true,
      };

    case 'CONFIRM_TILER_TILES':
    case 'DISCARD_TILER_TILES':
    case 'ERROR_RECEIVED':
    case 'RESET_TILER':
      return DEFAULT_STATE(state.siteHierarchy, state.id);

    case 'TOGGLE_VALIDATION_OVERLAY':
      return {
        ...state,
        validationOverlayVisible: !state.validationOverlayVisible,
      };

    default:
      return state;
  }
};
