let awsRegion = 'us-west-2';

export default {
 api_urls: {
   reporting: 'https://kyz6m0uhbc.execute-api.us-west-2.amazonaws.com/api',
   geojson: 'https://l5kprx8mnl.execute-api.us-west-2.amazonaws.com/api',
   overlays: 'https://epxz88qny1.execute-api.us-west-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.dev.droopy.forwoodsafety.com',
 forwood_id_url: 'https://id.dev.droopy.forwoodsafety.com?redirect_uri=https://geoeditor.dev.droopy.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.dev.droopy.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-west-2_XIn7YLlVP',
   userPoolWebClientId: '5uustij7rco8el3avmj5jren9c',
   cookieStorage: {
     domain: '.dev.droopy.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
 env: 'dev'
};
