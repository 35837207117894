import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';

import initial from './initial';
import rootReducer from '../reducers';
import api from '../middleware/api';
import auth from '../middleware/auth';
import localstorage from '../middleware/localstorage';
import notifications from '../middleware/notifications';
import uploadFile from '../middleware/uploadFile';
import setSiteOnReload from '../middleware/setSiteOnReload';
import mapReady from '../middleware/mapReady';

const middleware = applyMiddleware(
  api,
  auth,
  localstorage,
  notifications,
  uploadFile,
  setSiteOnReload,
  mapReady
);

const composedEnhancer = composeWithDevTools(middleware);

const store = createStore(rootReducer, initial(), composedEnhancer);

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const newRootReducer = require('../reducers').default;
      store.replaceReducer(newRootReducer);
    });
  }
}

export default store;
