import React from 'react';
import {Layout, Affix, Button} from 'antd';

/*
 * These components are quite heavy because of their Ant Design dependencies.
 * They're lazy loaded to save initial load time. You will see a lot of conditional
 * rendering below so code is only loaded when headers are clicked on
 */
import SiteSelector from '../Containers/SiteSelector';
import HierarchyTree from '../Containers/HierarchyTree';
import Legend from './Legend';

const {Sider} = Layout;

export default props => {
  const {isMobile, toggleFilterMenu, showFilterMenu, isUnauthorised} = props;

  /* No sidebar if access denied as some data shows within */
  if (isUnauthorised) return null;

  const toggleMenu = isMobile ? (
    <Affix
      style={{position: 'absolute', bottom: 10, right: 10, zIndex: 10000001}}>
      <Button
        onClick={toggleFilterMenu}
        size="large"
        shape="circle"
        type={showFilterMenu ? 'secondary' : 'primary'}
        icon="filter"
      />
    </Affix>
  ) : null;

  const filterMenu =
    !isMobile || showFilterMenu ? (
      <Sider
        width={isMobile ? '100%' : 400}
        theme="light"
        style={
          isMobile
            ? {
                position: 'absolute',
                overflow: 'auto',
                bottom: 30,
                zIndex: 10000000,
                boxSizing: 'border-box',
                left: 0,
              }
            : {}
        }>
        <div
          style={{
            padding: '0 20px 20px 20px',
          }}>
          <SiteSelector />
          <HierarchyTree />
          <Legend />
        </div>
      </Sider>
    ) : null;

  return (
    <React.Fragment>
      {filterMenu}
      {toggleMenu}
    </React.Fragment>
  );
};
