/*
 * This reducer is for editing or creating the polygon within a geoarea,
 * if you're looking to change geoarea attributes such as depth & risks, use
 * reducers/geoAreaEditing.js
 *
 * There are 2 modes, edit and create and for both modes the most important
 * things that get set are id and coords. From these the components can
 * work out the rest.
 */
export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_CREATE_TYPE':
      return {
        ...state,
        createType: action.createType,
      };

    case 'CREATE_CUSTOM_GEO_AREA_FROM_PHYSICAL_LOCATION':
      return {
        ...state,
        mode: 'CREATE',
        coords: [],
        id: state.physicalLocation,
        type: 'PHYSICAL_LOCATION',
      };

    case 'CREATE_CUSTOM_GEO_AREA_FROM_SITE':
      return {
        ...state,
        mode: 'CREATE',
        coords: [],
        id: state.physicalSite,
        type: 'SITE',
      };

    case 'SET_CUSTOM_GEO_AREA_COORDS':
      return {
        ...state,
        coords: action.coords,
        bounds: action.bounds
      };

    case 'SET_PHYSICAL_LOCATION_SELECTION':
      return {
        ...state,
        physicalLocation: action.value,
      };

    case 'SET_PHYSICAL_SITE_SELECTION':
      return {
        ...state,
        physicalSite: action.value,
      };

    case 'API_REQUEST_COMPLETE':
      if (
        action.status !== 'error' &&
        action.id === 'LOAD_LOCATION_HIERARCHY'
      ) {
        return {
          ...state,
          locationHierarchy: action.data.results,
        };
      }

      return state;

    case 'DISCARD_CUSTOM_GEO_AREA':
      return {
        ...state,
        mode: false,
        params: {},
        coords: [],
      };

    case 'EDIT_CUSTOM_GEO_AREA':
      return {
        ...state,
        mode: 'EDIT',
        coords: action.coords,
        id: action.id,
      };

    case 'EXAMPLE_SEEN':
      return {
        ...state,
        exampleSeen: true,
      };

    case 'TOGGLE_EXAMPLE_HIDE_IN_FUTURE':
      return {
        ...state,
        exampleHideInFuture: !state.exampleHideInFuture,
      };

    default:
      return state;
  }
};
