export default (state = {}, action) => {
  switch (action.type) {
    case 'API_REQUEST_COMPLETE':
      if (action.status !== 'error' && action.id === 'HIERARCHY') {
        return {
          ...state,
          hierarchy: action.data,
        };
      } else return state;

    case 'GOTO':
      return {
        ...state,
        coords: action.goto,
        name: action.name,
        tmpCoords: null,
      };

    case 'TOGGLE_GOTO':
      return {
        ...state,
        show: !state.show,
      };

    /*
     * Fired as the user entery coordinates.
     */
    case 'SET_TEMP_GOTO_COORDS':
      return {
        ...state,
        tmpCoords: action.coords,
      };

    /*
     * This is here so that as a user toggles the visibility of a geoarea,
     * it removes the goto - this makes the map zoom back to those
     * geoareas, otherwise user would be stuck at goto location
     */
    case 'SET_GEO_AREA_VISIBILITY':
      return {
        ...state,
        coords: null,
        name: null,
        tmpCoords: null,
      };

    default:
      return state;
  }
};
