import {combineReducers} from 'redux';

import customGeoArea from './customGeoArea';
import geoAreas from './geoAreas';
import physical from './physical';
import auth from './auth';
import modal from './modal';
import ui from './ui';
import loading from './loading';
import language from './language';
import mapTiler from './mapTiler';
import goto from './goto';
import importExport from './importExport';
import geoAreaEditing from './geoAreaEditing';
import overlays from './overlays';
import map from './map';
import risks from './risks';

export default combineReducers({
  customGeoArea,
  geoAreas,
  physical,
  auth,
  modal,
  ui,
  goto,
  language,
  loading,
  mapTiler,
  importExport,
  geoAreaEditing,
  overlays,
  map,
  risks
});
