import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Config from './Config';

/* IE11 Polyfills */
import 'core-js';
import 'react-app-polyfill/ie11';

import App from './App';
import store from './store';

const rootElem = document.getElementById('root');

// Helper to load the Intercom script dynamically
const loadIntercomScript = (src) => {
  return new Promise((resolve, reject) => {
    // Check if the script is already in the document
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = src;

    script.onload = () => resolve();
    script.onerror = (e) => reject(new Error(`Failed to load Intercom script: ${e.message}`));

    document.head.appendChild(script);
  });
};

// Check if Intercom is already initialized
const isIntercomLoaded = () => typeof window.Intercom === 'function';

// Function to initialize or update Intercom
const initializeOrUpdateIntercom = async (intercomSrc) => {
  if (!isIntercomLoaded()) {
    try {
      await loadIntercomScript(intercomSrc);
    } catch (error) {
      console.error('Error loading Intercom script:', error);
      return;
    }
  }

  const w = window;
  const ic = w.Intercom;

  if (typeof ic === 'function') {
    // If Intercom is already initialized, reattach and update settings
    ic('reattach_activator');
    if (w.intercomSettings) {
      ic('update', w.intercomSettings);
    }
  } else {
    // Queue up Intercom commands if not initialized
    const i = function () { i.c(arguments); };
    i.q = [];
    i.c = function (args) { i.q.push(args); };
    w.Intercom = i;

    if (w.intercomSettings) {
      w.Intercom('boot', w.intercomSettings);
    }
  }
};

// Dynamically load the Intercom script and initialize it based on the environment
const initializeIntercom = async (env) => {
  const intercomSrc = env === 'prod'
    ? 'https://widget.intercom.io/widget/tlmqy4u1'
    : 'https://widget.intercom.io/widget/slathgp8';

  // Load and initialize Intercom
  await initializeOrUpdateIntercom(intercomSrc);
};

initializeIntercom(Config.env);

const render = Component => {
  return ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,
    rootElem,
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
};
